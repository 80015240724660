import Headroom from "headroom.js";
var myElement = document.querySelector("header");

var options = {
    classes : {
        // when element is initialised
        initial : "header",
        // when scrolling up
        pinned : "header--pinned",
        // when scrolling down
        unpinned : "header--unpinned",
        // when above offset
        top : "header--top",
        // when below offset
        notTop : "header--not-top",
        // when at bottom of scroll area
        bottom : "header--bottom",
        // when not at bottom of scroll area
        notBottom : "header--not-bottom",
        // when frozen method has been called
        frozen: "header--frozen",
        // multiple classes are also supported with a space-separated list
        pinned: "header--pinned"
    },
};

var headroom = new Headroom(myElement, options);
var Fancybox = require('./plugins/fancyBox.min.js');

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = $('.wrapper');

function lockScreen() {
    disableBodyScroll(targetElement, {
        allowTouchMove: el => {
            while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
                return true;
            }
            el = el.parentElement;
            }
        },
    });
}

// import 'js-toc';
var toc = require('./plugins/toc.js');

if(document.querySelector('article')) {
    document.querySelector('article').toc({
        tocSelector: '#toc',
        tocIndent: true,
        smooth: true,
    });
}

jQuery(document).ready(function ($) {
    headroom.init();

    // var options = {
    //     opened: true,
    //     target: '.toc-content',
    //     smooth: true,
    //     headText: 'Inhoudsopgave',
    //     headBackgroundColor: '#171717',
    //     headTextColor: '#000',
    //     headLinkColor: '#fff',
    //     bodyBackgroundColor: '#171717',   
    //     bodyLinkColor: '#fff',
    //     borderWidth: '0px',
    // };
    // $.toctoc(options);
    
    // Mobile toggle
    $('.header__toggle').on('click', function (e) {
        e.preventDefault();
        if($('.header--mobile')[0]) {
            enableBodyScroll(targetElement);
        } else {
            lockScreen();
        }
        $('.header').toggleClass('solid');
        $('.header').toggleClass('header--mobile');
        $('.dropdown-1').removeClass('open');
        $('.dropdown-2').removeClass('open');
    });

    // Header
    const smallDevice = window.matchMedia("(min-width: 768px)");

    smallDevice.addListener(handleDeviceChange);

    function handleDeviceChange(e) {
        if (e.matches) {
            enableBodyScroll(targetElement);
            // Dropdown
            $(".hover-dropdown-1, .dropdown-1").hover(
                function() {
                    lockScreen();
                    $('.dropdown-1').addClass('open');
                    $('.overlay').addClass('open');
                    $('.header').addClass('solid');
                },
                function() {
                    enableBodyScroll(targetElement);
                    $('.dropdown-1').removeClass('open');
                    $('.overlay').removeClass('open');
                    $('.header').removeClass('solid');
                }
            );

            $(".hover-dropdown-2, .dropdown-2").hover(
                function() {
                    lockScreen();
                    $('.dropdown-2').addClass('open');
                    $('.overlay').addClass('open');
                    $('.header').addClass('solid');
                },
                function() {
                    enableBodyScroll(targetElement);
                    $('.dropdown-2').removeClass('open');
                    $('.overlay').removeClass('open');
                    $('.header').removeClass('solid');
                }
            );
        } else {
            $('.header').removeClass('header--mobile');
            
            $(".hover-dropdown-1 a").on('click', function(e) {
                e.preventDefault();
                $('.dropdown-1').addClass('open');
            });

            $(".hover-dropdown-2 a").on('click', function(e) {
                e.preventDefault();
                $('.dropdown-2').addClass('open');
            });

            $(".dropdown__back").on('click', function(e) {
                e.preventDefault();
                $('.dropdown-1').removeClass('open');
                $('.dropdown-2').removeClass('open');
            });
        }
    }
    handleDeviceChange(smallDevice);

    // FAQ
    $('.block--faq .block__header').on('click', function(e) {
        e.preventDefault();
        $(this).closest('.block--faq').toggleClass('block--faq-open');
    });

    // Reviews
    $('.block__names .block__item').on('click', function(e) {
        e.preventDefault();

        var ID = $(this).data('name');

        $(".block__names .block__item:not([data-name='" + ID + "'])").removeClass('active');
        $(".block__names .block__item[data-name='" + ID + "']").addClass('active');

        $(".block__reviews .block__item:not([data-content='" + ID + "'])").hide();
        $(".block__reviews .block__item[data-content='" + ID + "']").show();
    });
    
    if($('#input_2_30')[0]) {
        $("#input_2_30").datepicker();
    }


    if($('.ticker')[0]) {
        gsap.set(".ticker ul", {xPercent: -100})

        gsap.to(".ticker ul", {
            xPercent: 50,
            scrollTrigger: {
                scrub: true,
                start: "top bottom",
                end: "bottom top",
                markers: false
            }
        });
    }
});